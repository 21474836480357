import { render, staticRenderFns } from "./profile-timeline.vue?vue&type=template&id=42b29cf6&scoped=true&"
import script from "./profile-timeline.vue?vue&type=script&lang=ts&"
export * from "./profile-timeline.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42b29cf6",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
installComponents(component, {VFlex,VLayout,VTimeline})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src551672837/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('42b29cf6')) {
      api.createRecord('42b29cf6', component.options)
    } else {
      api.reload('42b29cf6', component.options)
    }
    module.hot.accept("./profile-timeline.vue?vue&type=template&id=42b29cf6&scoped=true&", function () {
      api.rerender('42b29cf6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/profile/partials/timeline/profile-timeline.vue"
export default component.exports